<template>
  <div class="wrap" :style="{'paddingTop': isNotice&&showBox ? '1.2rem':'0.4rem'}">
    <Loading v-show="$store.state.isLoading"/>
    <van-form v-show="!$store.state.isLoading" @submit="onSubmit" label-width="7em" input-align="right" error-message-align="right">
      <div class="noticeBox" v-if='isNotice&&showBox'>
        <img class="noticeImg" src='@/assets/img/org_notice.png'/>
        <span class="noticeText">您已申请过变更还款日，再次申请需要对公转账200元手续费</span>
        <img class="noticeClose" @click="closeBox" src='@/assets/img/org_close.png'/>
      </div>
      <template>
        <van-field v-if='acc_name' readonly label="我司账户名称" :value="acc_name" />
        <van-field v-if='acc_num' readonly label="我司账号" :value="acc_num" />
        <van-field v-if='acc_bank' readonly label="我司账户开户行" type="textarea" rows="2" :autosize="true" :value="acc_bank" />
        <van-field v-if='fee' readonly label="手续费" :value="fee+'元'" />
        <van-field
          v-model="upFile"
          v-if='isNotice'
          name="upFile"  
          label="转账截图上传" 
          right-icon="arrow"
          @click="handdleUpfile">
          <template #input >
            <span class="spanUpfile" v-if="upFile=='0'" type="default" size="small">未上传</span>
            <span class="spanUpfile" v-else type="default" size="small">已上传</span>
          </template>
        </van-field>
      </template>
      <van-field
        readonly
        v-model="userName"
        name="userName"
        label="姓名"
        placeholder="姓名"
      />
      <van-field
        v-if="columns.length =='1'"
        autosize
        readonly
        v-model="contractNum"
        name="contractNum"
        label="合同号"
      />
      <van-field
        v-if="columns.length !='1'"
        autosize
        readonly
        clickable
        name="contractNum"
        :value="contractNum"
        label="合同号"
        @click="showContractPicker = true"
        right-icon="arrow-down"
      />
      <van-popup v-model="showContractPicker" position="bottom">
        <van-picker
          show-toolbar
          :columns="columns"
          @confirm="onContractConfirm"
          @cancel="showContractPicker = false"
        />
      </van-popup>
      <van-field
        readonly
        v-model="vin"
        name="vin"
        label="车架号"
        placeholder="车架号"
      />
      <van-field
        v-if="isReadonly"
        readonly
        v-model="date"
        name="date"
        label="变更后还款日"
        placeholder="变更后还款日"
      />
      <van-field
        v-if="!isReadonly"
        readonly
        clickable
        name="date"
        :value="date"
        label="变更后还款日"
        placeholder="请选择"
        @click="onDateClick"
        right-icon="arrow-down"
        :rules="[{ required: true}]"
      />
      <van-popup v-model="showCalendar" position="bottom">
        <van-picker
          show-toolbar
          :columns="dateColumns"
          @confirm="onDateConfirm"
          @cancel="showCalendar = false"
        />
      </van-popup>
      <!-- <van-field
        v-if="!isReadonly"
        readonly
        clickable
        name="date"
        :value="date"
        label="变更后还款日"
        placeholder="请选择"
        @click="showCalendar = true"
        right-icon="arrow-down"
        :rules="[{ required: true}]"
      />
      <van-calendar v-model="showCalendar" @confirm="onDateConfirm" /> -->
      <van-field
        readonly
        class="date"
        v-model="planDate"
        name="planDate"
        label="变更后计划日期"
        placeholder="变更后计划日期"
      />
      <van-field
        readonly
        v-model="rentAmount"
        name="rentAmount"
        label="多还款租息"
        placeholder="多还款租息"
      />
      <van-field
        readonly
        v-model="nextRent"
        name="nextRent"
        label="下月应扣款金额"
        placeholder="下月应扣款金额"
      />
      <van-field
        v-if="isReadonly"
        readonly
        v-model="currentTime"
        type="currentTime"
        name="申请时间"
        label="申请时间"
        placeholder="申请时间"
        :rules="[{ required: true, message: '请填写申请时间' }]"
      />
      <div class="tips">
        <p>温馨提示：</p>
        <p style="color:#ee0a24;">1、当月办理，当月还款日不变，下月还款日变更为修改后的，举例：3月申请，3月还款日不变，4月还款日变为修改后的。</p>
        <p>2、变更日期无法跨月选择。</p>
        <!-- <p>4、手续费仅在生效当月收取一次，不会每月都收取 </p> -->
        <p>3、此业务在申请成功后7个工作日内修改完成。</p>
        <p>4、如变更次数>=2次，请先对公转账200元手续费，支付手续费后才能变更。</p>
        <p>5、多还款租息仅在生效当月收取一次，不会每月都收取。</p>
        <p>6、申请进度可在【我的申请】中查看。</p>
      </div>
      <van-col span="20" offset="2" style="position:fixed;bottom:10px;">
        <van-button :disabled="isReadonly" round block type="danger" native-type="submit">
        提交
        </van-button>
      </van-col>
      <!-- <div style="margin: 16px;">
        <van-button round block type="danger" native-type="submit">
        提交
        </van-button>
      </div> -->
    </van-form>
    <van-popup
        v-model="showVanpopup"
        :close-on-click-overlay="false"
        round
        position="bottom"
        :style="{ height: '290px' }"
      >
      <div class="tipTitile">提示</div>
        <div class="tipContent" style="margin-bottom:70px">
          <ul>
            <li>您此期存在挂账金额，请联系我司客服<a class="linka" href="tel:4000011255">4000011255</a>进行申请</li>
          </ul>
        </div>
        <div class="footerBox" @click="confirmKnow">
          <div class="footerBtn">我知道了</div>
        </div>
      </van-popup>
  </div>
</template>
<script>
  import moment from 'moment';
  import { Dialog,Toast } from 'vant';
  import {modifyRepayment,changeDates,modifyRepaymentSub,getOpenId} from '@/server'
  import Loading from "@/components/Loading";
  import {setCookie,getCookie,domainUrl} from '@/lib/utils';
  export default {
    components: {
      Loading
    },
  data() {
    return {
      isNotice: false,
      showBox: true,
      showVanpopup:false,//判断是否有回笼金额，若有则为true显示提示框
      cardId:this.$store.state.cardId,
      accessToken:this.$store.state.accessToken,
      // openId:localStorage.getItem("openId") ||this.$store.state.openId,
      openId:getCookie('openId'),
      code:this.$route.query.code || '',
      isReadonly:false,
      userName: '',
      contractNum: '',
      vin: '',
      planDate:'',
      showContractPicker: false,
      date: '',
      showCalendar: false,
      columns: [],
      showBankPicker: false,
      currentTime:'',
      rentAmount:'',
      nextRent:'',//下月应扣金额
      dataList:[],
      dataDetail:{},//切换合同后的数据
      isYuQi:'1',//是否逾期，0:是，1否
      isHuiLong:'1',//是否有回笼金额，0:有，1没有
      isQiZu:'1',//是否是起租日，0:有，1没有
      upFile:'',//附件是否上传
      backFlag:this.$store.state.backFlag,//是不是isReadonly返回1:false,0:true
      backFlagNum: this.$store.state.backFlagNum,//上传图片页返回 contractNum
      fileList: [],
      chg_num: 0,
      dateColumns:[],
      acc_name: '',
      acc_num: '',
      acc_bank: '',
      fee: ''
    };
  },
  created(){
    sessionStorage.setItem('key', 0);
     //如果有openid
    if(this.openId){
      getOpenId({openid:this.openId}).then(res=>{
        if(res.wx_code=='0'){
          this.$store.commit('bindStatus', res.bind_status);
          localStorage.setItem("bindStatus", res.bind_status);
          localStorage.setItem("accessToken", res.access_token);
          this.$store.commit('accessToken', res.access_token);
          setCookie('openId',res.openid);
          this.$store.commit('openId', res.openid);
          this.$store.commit('cardId', res.card_id);
          localStorage.setItem("cardId", res.card_id);
          this.cardId = res.card_id;
          this.accessToken = res.access_token;
          this.openId = res.openid;
          if(res.bind_status !=='1'){
            this.$router.push({ path: '/login?redirect=/modifyRepayment' });
          }else{
            this.getFlagDetail();
          }
        }else{
          Toast.fail({
            message: res.msg,
            duration:'3000'
          });
        }
      })
    } else{
      if(!this.code) {
        this.$store.commit('bindStatus', '1');
        localStorage.setItem("bindStatus", '1');
        let uiCode = encodeURIComponent(domainUrl+'/modifyRepayment');
        window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxe40be82e1238dfe7&redirect_uri='+uiCode+'&response_type=code&scope=snsapi_userinfo&state=STATE&connect_redirect=1#wechat_redirect';         
      }else{//获取code
        getOpenId({percode:this.code}).then(res=>{
          if(res.wx_code=='0'){
            this.$store.commit('bindStatus', res.bind_status);
            localStorage.setItem("bindStatus", res.bind_status);
            this.$store.commit('accessToken', res.access_token);
            localStorage.setItem("accessToken", res.access_token);
            this.$store.commit('openId', res.openid);
            setCookie('openId',res.openid);
            this.$store.commit('cardId', res.card_id);
            localStorage.setItem("cardId", res.card_id);
            this.cardId = res.card_id;
            this.accessToken = res.access_token;
            this.openId = res.openid;
            if(res.bind_status !=='1'){
              this.$router.push({ path: '/login?redirect=/modifyRepayment' });
            }else{
              this.getFlagDetail();
            } 
          }else{
            Toast.fail({
              message: res.msg,
              duration:'3000'
            });
          }
        });
      }
    }
  },
  mounted(){
    let that = this;
    sessionStorage.setItem('key', 'modify2');
    window.addEventListener("popstate", function(e) {  //回调函数中实现需要的功能
    	if(sessionStorage.getItem('key') == 'modify2') {
        that.$router.push({ path: '/personalCenter' });
      }
    }, false);
  },
  methods: {
    closeBox(){
      this.showBox = false
    },
    confirmKnow(){
      this.showVanpopup = false;
    },
    getFlagDetail(){
      if(this.backFlag =="1"){//backFlag为从上传图片页返回的时候所携带的合同号
      this.columns = []
        var res = this.$store.state.corporateDataList;
        this.dataList = res;
        res.map(item => {
          this.columns = [...this.columns,item.contract_number]
        });
        var imgUrlLength = this.$store.state.imgUrlList.length;
        this.upFile = imgUrlLength;
        var params = this.$store.state.writeData;//已经填写没有提交的数据
        this.contractNum = this.backFlagNum;
        this.chg_num = params.chg_num
        this.showBox = true
        if(this.chg_num >= 2){
          this.isNotice = true
        }else{
          this.isNotice = false
        }
        this.userName = params.userName;
        this.vin = params.vin;
        this.date = params.date
        this.planDate = params.planDate
        this.rentAmount = params.rentAmount
        this.nextRent = params.nextRent
        this.acc_name = params.acc_name
        this.acc_num = params.acc_num
        this.acc_bank = params.acc_bank
        this.fee = params.fee
        this.dataDetail = params.dataDetail
        for (var i = 0; i < params.dataDetail.nextmonth_endday; i++) {
          this.dateColumns[i] = (i + 1).toString();
        }
        var myDate = new Date();
        this.currentTime = this.formatDate(myDate.getTime(),true);
        this.fee_url = params.fee_url
        this.$store.commit('backFlagNum', '');
        this.$store.commit('backFlag', '');
      }else if(this.backFlag =="0"){//只读
        modifyRepayment({access_token:this.accessToken,card_id:this.cardId}).then(res=>{
          this.columns=[];
          this.dataList = res.data;
          res.data.map(item => {
            this.columns = [...this.columns,item.contract_number]
          });
          this.$store.commit('corporateDataList', res.data);
          this.onContractConfirm(this.backFlagNum);
          this.$store.commit('backFlagNum', '');
          this.$store.commit('backFlag', '');
        })
      }else{
        this.getDetail();
      }
    },
    getDetail(){
      this.$store.commit('imgUrlList', []);
      this.isYuQi = '1';
      this.isHuiLong = '1';
      this.isQiZu = '1';
      this.columns=[];
      var myDate = new Date();
      this.currentTime = this.formatDate(myDate.getTime(),true);
      //信息返显
      const params = {
        access_token:this.accessToken,
        card_id:this.cardId
      }
      let res = this.$store.state.dataListTotal;
      // modifyRepayment(params).then(res=>{
        this.dataList = res;
        this.dataDetail = res[0];
        var data = res[0];
        res.map(item => {
          this.columns = [...this.columns,item.contract_number]
        });
        this.$store.commit('corporateDataList', res);
        this.chg_num = data.chg_num
        this.showBox = true
        if(this.chg_num >= 2){
          this.isNotice = true
        }else{
          this.isNotice = false
        }
        this.fileList = data.fee_url && data.fee_url.split(',') || []
        this.upFile = this.fileList.length
        if(data.wx_code == '200'){//未提交
          this.userName = data.customername;
          this.vin = data.vin;
          this.contractNum = this.columns[0];
          for (var i = 0; i < data.nextmonth_endday; i++) {
            this.dateColumns[i] = (i + 1).toString();
          }
          if(this.isNotice){
            this.acc_name = '天津欧拉融资租赁有限公司'
            this.acc_num = '13050166530800000457'
            this.acc_bank = '中国建设银行股份有限公司保定恒祥南大街支行'
            this.fee = '200'
          }else{
            this.acc_name = ''
            this.acc_num = ''
            this.acc_bank = ''
            this.fee = ''
          }
          if(data.isoverdue == 'y'){//是否逾期:isoverdue,y代表逾期,n代表未逾期;
            this.isYuQi = '0';
            Dialog.alert({
              message: '您存在逾期租金，请及时还款'
            });
          }
          if(data.isnextpay == 'y'){//是否有回笼金额:isnextpay,判断申请月份下一月份的期次（变更日所在期）是否有还款额,y代表有还款额,n代表无还款额;
            this.isHuiLong = '0';
            this.showVanpopup = true;
          }
          if(data.issartday == 'y'){//是否是起租日:issartday,系统操作日期如果等于起租日,则返回y,否则返回n;
            this.isQiZu = '0';
            Dialog.alert({
              message: '起租日当天无法申请还款日变更，请次日再试'
            });
          }
        }else if(data.wx_code == '506'){//已提交,信息返显且不可修改
          this.showDataDisable(data);
        }else{
          Toast.fail({
            message: data.msg,
            duration:'3000'
          });
        }
      // });
    },
    validator(val) {
      var imgurl = this.$store.state.imgUrlList;
      if(val=='0' || imgurl=='' || imgurl == [] || imgurl==null || imgurl==undefined){
        return false;
      }
    },
    onDateClick(){
      if(this.isYuQi=='0'){
        this.showCalendar = false;
        Dialog.alert({
          message: '您存在逾期租金，请及时还款'
        });
      }else if(this.isHuiLong=='0'){
        this.showCalendar = false;
        this.showVanpopup = true;
      }else if(this.isQiZu=='0'){
        this.showCalendar = false;
        Dialog.alert({
          message: '起租日当天无法申请还款日变更，请次日再试'
        });
      }else{
        this.showCalendar = true;
      }
    },
    handdleUpfile(){
      if(this.isYuQi=='0'){
        Dialog.alert({
          message: '您存在逾期租金，请及时还款'
        });
      }else if(this.isHuiLong=='0'){
        this.showVanpopup = true;
      }else if(this.isQiZu=='0'){
        Dialog.alert({
          message: '起租日当天无法申请还款日变更，请次日再试'
        });
      }else{
        const params = {
          userName:this.userName,
          vin:this.vin,
          date:this.date,
          planDate:this.planDate,
          rentAmount:this.rentAmount,
          nextRent: this.nextRent,
          chg_num: this.chg_num,
          acc_bank: this.acc_bank,
          acc_name: this.acc_name,
          acc_num: this.acc_num,
          fee: this.fee,
          dataDetail: this.dataDetail
        }
        this.$store.commit('writeData', params);
        console.log(this.fileList)
        this.$store.commit('fileList', this.fileList);
        this.$router.push({ 
          path: '/upFile',
          query: { 
            conNo: this.contractNum,
            isReadonly:this.isReadonly,
            imgtype:'modify_repayment'
          }
        });
      }
    },
    onDateConfirm(value) {
      this.showCalendar = false;
      this.date = value;
      // this.date = this.formatDate(date);
      // var i = this.date.length;
      // this.date = this.date.substr(i-2);
      var dateValue = Number(value) < 10 ? '0'+value : value;
      if(this.dataDetail.payday == dateValue){//变更后日期不能等于原还款日
        this.date = '';
        this.planDate = '';
        this.rentAmount = '';
        this.nextRent = '';
        Dialog.alert({
          message: '变更后日期不能等于原还款日'
        });
      }else{
        const params = {
          contract_number:this.contractNum,
          newdate:this.date
        }
        changeDates(params).then(res=>{
          var data = res.data[0];
          if(res.status=='200'){
            this.planDate = data.new_plan_day;
            this.rentAmount = data.add_interest;
            this.nextRent = data.next_rent;
          }else{
            Toast.fail({
              message: res.msg,
              duration:'3000'
            });
          }
        })
      }
    },
    onContractConfirm(value) {
      this.fileList = [];
      this.upFile = '0'
      this.$store.commit('backFlag', '');
      this.$store.commit('imgUrlList', []);
      this.isYuQi = '1';
      this.isHuiLong = '1';
      this.isQiZu = '1';
      var dataList = this.dataList;
      var wxCode = '';
      var index = '';
      this.contractNum = value;
      this.showContractPicker = false;
      this.dataList.map((item,i) => {
        if(item.contract_number == value){
          wxCode = item.wx_code;
          index = i
        }
      });
      this.chg_num = this.dataList[index].chg_num
      this.showBox = true
      if(this.chg_num >= 2){
        this.isNotice = true
      }else{
        this.isNotice = false
      }
      this.vin = this.dataList[index].vin;
      for (var i = 0; i < this.dataList[index].nextmonth_endday; i++) {
        this.dateColumns[i] = (i + 1).toString();
      }
      this.userName = this.dataList[index].customername;
      this.fileList = this.dataList[index].fee_url && this.dataList[index].fee_url.split(',') || []
      this.upFile = this.fileList.length
      this.dataDetail = this.dataList[index];
      if(wxCode=='506'){
        this.showDataDisable(dataList[index]);
      }else if(wxCode=='200'){
        var myDate = new Date();
        this.planDate = '';
        this.rentAmount = '';
        this.nextRent = '';
        this.date = '';
        this.currentTime = this.formatDate(myDate.getTime(),true);
        this.isReadonly = false;
        if(this.isNotice){
            this.acc_name = '天津欧拉融资租赁有限公司'
            this.acc_num = '13050166530800000457'
            this.acc_bank = '中国建设银行股份有限公司保定恒祥南大街支行'
            this.fee = '200'
          }else{
            this.acc_name = ''
            this.acc_num = ''
            this.acc_bank = ''
            this.fee = ''
          }
        if(dataList[index].isoverdue == 'y'){//是否逾期：isoverdue，y代表逾期，n代表未逾期;
          this.isYuQi = '0';
          Dialog.alert({
            message: '您存在逾期租金，请及时还款'
          });
        }
        if(dataList[index].isnextpay == 'y'){//是否有回笼金额：isnextpay，判断申请月份下一月份的期次（变更日所在期）是否有还款额，y代表有还款额，n代表无还款额;
          this.isHuiLong = '0';
          this.showVanpopup = true;
        }
        if(dataList[index].issartday == 'y'){//是否是起租日:issartday,系统操作日期如果等于起租日,则返回y,否则返回n;
          this.isQiZu = '0';
          Dialog.alert({
            message: '起租日当天无法申请还款日变更，请次日再试'
          });
        }
      }else{
        Toast.fail({
          message: dataList[index].msg,
          duration:'3000',
        }); 
      };
    },
    formatDate(date, flag) {
      if (!date) return '--';
      if (flag) {
        return moment(date).format('YYYY-MM-DD HH:mm:ss');
      } else {
        return moment(date).format('YYYY-MM-DD');
      }
    },
    showDataDisable(data){//已提交过后不能再次提交且信息不能修改
      if(data.deal_status=='0'){
        Toast.success({
          message: "审核中",
          duration:'3000'
        });
      }else if(data.deal_status=='1'){
        Toast.success({
          message: "一个月内已变更过,不能再次申请",
          duration:'3000'
        });
      }
      this.userName = data.customername;
      this.vin = data.vin;
      this.contractNum = data.contract_number;
      this.planDate = data.new_plan_day;
      this.rentAmount = data.add_interest;
      this.nextRent = data.next_rent;
      this.currentTime = data.apply_time;
      this.date = data.newdate;
      this.isReadonly = true;
      this.acc_name = data.acc_name
      this.acc_num = data.acc_num
      this.acc_bank = data.acc_bank
      this.fee = data.fee
      //data.approval_status,//0审核中/1通过/2退回/3退回再次申请
    },
    onSubmit(values) {
      var myDate = new Date();
      this.currentTime = this.formatDate(myDate.getTime(),true);
      let imgUrlList = this.$store.state.imgUrlList
      if(this.chg_num >= 2 && imgUrlList.length == 0){
        Toast('未上传转账截图，请上传后重新提交')
        return false
      }
      let transferAccounts = {}
      if(this.chg_num >= 2){
        transferAccounts = {
          fee_url: imgUrlList.toString(),
          acc_name: this.acc_name,
          acc_num: this.acc_num,
          acc_bank: this.acc_bank,
          fee: this.fee
        }
      }
      const params = {
        access_token:this.accessToken,
        card_id:this.cardId,
        contract_number:values.contractNum,
        newdate:values.date,
        vin:values.vin,
        customername:values.userName,
        new_plan_day:values.planDate,
        add_interest:values.rentAmount,
        next_rent:values.nextRent,
        apply_time:this.currentTime,
        chg_num: this.chg_num,
        payday: this.dataDetail.payday,
        ...transferAccounts
      }
      Dialog.confirm({
        message: '申请后无法撤销\n请确认是否提交',
      })
      .then(() => {
        // on confirm
        modifyRepaymentSub(params).then(res=>{
          // var data = res.data[0];
          // const that = this;
          if(res.status=='200'){
            Toast({
              message: '提交成功！',
              icon: require('@/assets/img/bindSuccess.png'),
              duration:'3000',
            }); 
            // this.getDetail();
            modifyRepayment({access_token:this.accessToken,card_id:this.cardId}).then(res=>{
              this.columns=[];
              this.dataList = res.data;
              res.data.map(item => {
                this.columns = [...this.columns,item.contract_number]
              });
              this.$store.commit('corporateDataList', res.data);
              this.onContractConfirm(values.contractNum);
            })
          }else{
            Toast({
              message: res.msg,
              icon: require('@/assets/img/bindError.png'),
              duration:'3000'
            });
          }
        })
      })
      .catch(() => {
        // on cancel
      });
    },
  },
};
</script>
<style scoped>
.wrap{
    padding: 1.2rem 0 0.4rem;
    background-color: #fff;
  }
.van-button--default{
  border: 0px;
}
.tips{
  color:#808080;
  font-size:13px;
  margin-top: 20px;
  margin-bottom: 60px;
}
.tips p{
    margin: 6px 15px;
}
.noticeBox{
  width: 100%;
  height: 1.2rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  background-color: #FFF9ED;
  position: fixed;
  top: 0;
  z-index: 1;
}
.noticeImg{
  width: 0.36rem;
  margin-left: 0.28rem;
  margin-right: 0.12rem;
  margin-top: 0.22rem;
}
.noticeText{
  color: #FF6010;
  font-size: 14px ;
  margin-top: 0.18rem;
}
.noticeClose{
  width: 0.36rem;
  margin-left: 0.28rem;
  margin-right: 0.28rem;
  margin-top: 0.22rem;
}
</style>
<style>
textarea{
  overflow:hidden;
}
.wrap .van-field__control{
  color: #666666 !important;
}
/* #app{
  background: #fff !important;
} */
.date .van-field__label{
  width: 8em;
}
.tipTitile{
  color: #1A1A1A;
  font-size: 0.35rem;
  font-weight: bold;
  text-align: center;
  border-bottom: 2px solid #eee;
  height: 50px;
  line-height: 50px;
  /* margin-top: 10px; */
}
.tipContent{
  background-color: #F5F5F5;
  margin: 20px 20px;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 0.3rem;
}
.tipContent ul{
  margin-left: 23px;
}
.tipContent li{
  list-style-type: disc;
  color: #333;
  /* font-size: 16px; */
  margin: 15px 0px;
}
.tipContent li .linka{
  text-decoration:underline;
  color: #247FFF;
  margin-right:5px;
  margin-left:5px;
}
.footerBox{
  background-color: #ffffff;
  padding: 0.2rem 0;
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;
	position: fixed;
	bottom: 0;
}
.footerBtn {
  width: 6.7rem;
  height: 0.9rem;
  border-radius: 0.9rem;
  text-align: center;
  line-height: 0.9rem;
  background-color: #e50012;
  color: #ffffff;
}
</style>